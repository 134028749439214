// import logo from './logo.svg';
import { useState } from 'react';
import './App.css';

function App() {
  const [noTime, setNoTime] = useState(0);
  const [msg, setmsg] = useState('โอ้! นายมันเลิศที่สุดที่ยอมรับตัวเองได้ 👍🏼✨');
  const kuy_n = () => {
    setmsg("เยี่ยมมาก นายคือเกย์ตัวจริง! 👍🏼")
    const no_btn = document.getElementById('n-btn')
    const yes_btn = document.getElementById('y-btn')
    
    setNoTime(noTime+1);
    if(noTime > 9){
      no_btn.style.display = "none";
    }

    const maxX = window.innerWidth-150;
    const maxY = window.innerHeight-150;
    
    no_btn.style.position = "absolute"
    no_btn.style.left = (Math.random() * maxX)+"px"
    no_btn.style.top = (Math.random() * maxY)+"px"
    
  }

  const kuy_y = () => {
    const no_btn = document.getElementById('n-btn')
    const title_great = document.getElementById('t-great');
    const yes_btn = document.getElementById('y-btn')
    yes_btn.style.display = "none"
    no_btn.style.display = "none"
    title_great.style.display = "block"
  }

  return (
    <div className='main-container'>
      <h1>นายเป็นเกย์ใช่บ่ ?</h1>
      <h3 id="t-great" style={{"display":"none","color":"red"}}>{msg}</h3>
      <div className='button-container'>
        <button id="y-btn" className="btn-yes" onClick={kuy_y}>ชั่ยส์ ✅</button>
        <button id="n-btn" className="btn-no" onClick={kuy_n} onMouseOver={kuy_n}>ไม่ ❌</button>
      </div>
      
      <p style={{paddingTop: "5rem",position:"block"}}
      ><i>Made by น้องไอไหร (<a href="https://www.instagram.com/nongicelife">@nongicelife</a>)</i>
      <br/>ออกแบบมาให้เล่นในจอคอมเป็นหลัก :P
      </p>
    </div>
  );
}

export default App;
